<template>
  <div class="user-profile--gallery" v-if="user">
    <v-row v-if="hasInitialPhotos" class="mb-10">
      <v-col cols="12" md="11">
        <h2>Onboarding Photos</h2>
        <v-divider class="mt-4" />
      </v-col>
      <v-col cols="12" md="11">
        <v-row>
          <v-col
            v-for="(photo, key) in gallery.initial"
            :key="key"
            class="d-flex child-flex"
            cols="12"
            md="3"
          >
            <photo-card
              v-if="photo"
              :src="photo.thumb_url"
              @open="
                openCarousel(gallery.initial, `initial`, photo.collection_name)
              "
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row v-if="hasProgressPhotos">
      <v-col cols="12" md="11">
        <h2>Progress Photos</h2>
        <v-divider class="mt-4" />
      </v-col>
      <v-col
        cols="12"
        md="11"
        v-for="week in gallery.weekly"
        :key="week.id"
        class="mb-8"
      >
        <div class="d-flex justify-space-between align-center flex-wrap">
          <h3 class="headline primary--text">
            {{ printDate(week.period_from) }} - {{ printDate(week.period_to) }}
          </h3>
        </div>

        <v-row>
          <v-col
            class="d-flex child-flex"
            cols="12"
            md="3"
            v-if="week.back_photo"
          >
            <photo-card
              :src="week.back_photo.thumb_url"
              title="BACK"
              @open="
                openCarousel(week, `weekly`, week.back_photo.collection_name)
              "
            />
          </v-col>
          <v-col
            class="d-flex child-flex"
            cols="12"
            md="3"
            v-if="week.front_photo"
          >
            <photo-card
              :src="week.front_photo.thumb_url"
              title="FRONT"
              @open="
                openCarousel(week, `weekly`, week.front_photo.collection_name)
              "
            />
          </v-col>
          <v-col
            class="d-flex child-flex"
            cols="12"
            md="3"
            v-if="week.side_photo"
          >
            <photo-card
              :src="week.side_photo.thumb_url"
              title="SIDE"
              @open="
                openCarousel(week, `weekly`, week.side_photo.collection_name)
              "
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row v-if="hasSelfies">
      <v-col cols="12" md="11">
        <h2>Selfies</h2>
        <v-divider class="mt-4" />
      </v-col>
      <v-col cols="12" md="11">
        <v-row>
          <v-col
            v-for="(photo, key) in gallery.selfies"
            :key="key"
            class="d-flex child-flex"
            cols="12"
            md="3"
          >
            <photo-card
              v-if="photo"
              :src="photo.thumb_url"
              @open="
                openCarousel(gallery.selfies, `selfies`, photo.collection_name)
              "
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row v-if="!hasInitialPhotos && !hasProgressPhotos && !hasSelfies">
      <v-col class="text-center">
        <span class="display-1 grey--text">
          NO PHOTOS
        </span>
      </v-col>
    </v-row>

    <v-overlay absolute :value="loading" opacity="0.1">
      <v-progress-circular
        indeterminate
        color="primary"
        size="64"
      ></v-progress-circular>
    </v-overlay>

    <photo-carousel
      :show="showCarousel"
      :photos="carouselItems"
      @close="showCarousel = false"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import PhotoCard from '../components/PhotoCard'
import PhotoCarousel from '../components/PhotoCarousel'
import dayjs from 'dayjs'

export default {
  name: 'GalleryTab',

  components: { PhotoCard, PhotoCarousel },

  data() {
    return {
      gallery: null,
      loading: false,
      showCarousel: false,
      carouselItems: [],
    }
  },

  computed: {
    ...mapGetters({
      user: 'users/currentSelectedUserProfile',
      selectedProgram: 'users/currentSelectedProgram',
    }),

    hasInitialPhotos() {
      if (this.gallery && this.gallery.initial) {
        if (
          this.gallery.initial.back ||
          this.gallery.initial.front ||
          this.gallery.initial.side
        ) {
          return true
        }
      }
      return false
    },

    hasProgressPhotos() {
      if (this.gallery && this.gallery.weekly) {
        return !!this.gallery.weekly.length
      }
      return false
    },

    hasSelfies() {
      if (this.gallery && this.gallery.selfies) {
        return !!this.gallery.selfies.length
      }
      return false
    },
  },
  watch: {
    selectedProgram: async function(program, oldProgram) {
      if (oldProgram && program.id !== oldProgram.id) {
        this.fetchUserGallery()
      }
    },
  },

  methods: {
    ...mapActions({
      getUserPhotoGallery: 'users/getUserPhotoGallery',
    }),

    async fetchUserGallery() {
      if (!this.selectedProgram.id || !this.user.id || this.loading) return

      const userId = this.$route.params.id

      this.loading = true

      await this.getUserPhotoGallery({
        userId: userId,
        programId: this.selectedProgram.user_program.id,
      }).then((data) => {
        this.gallery = data
      })

      this.loading = false
    },

    printDate(date) {
      return date ? dayjs.utc(date).format('MMM DD, YYYY') : null
    },

    openCarousel(photos, album, body) {
      this.carouselItems = []
      this.showCarousel = true

      if (album === 'initial') {
        if (photos.back) {
          this.addPhoto(photos.back, body)
        }
        if (photos.front) {
          this.addPhoto(photos.front, body)
        }
        if (photos.side) {
          this.addPhoto(photos.side, body)
        }
      }

      if (album === 'weekly') {
        if (photos.back_photo) {
          this.addPhoto(photos.back_photo, body)
        }
        if (photos.front_photo) {
          this.addPhoto(photos.front_photo, body)
        }
        if (photos.side_photo) {
          this.addPhoto(photos.side_photo, body)
        }
      }

      if (album === 'selfies') {
        this.gallery.selfies.forEach((photo) => {
          this.addPhoto(photo, body)
        })
      }
    },

    addPhoto(photo, body) {
      if (photo.collection_name === body) {
        this.carouselItems.unshift({ src: photo.url })
      } else {
        this.carouselItems.push({ src: photo.url })
      }
    },
  },

  created() {
    this.fetchUserGallery()
  },
}
</script>
