<template>
  <v-row justify="center" v-if="show">
    <div class="custom-close" @click=";(slide = 0), $emit('close')">
      <span>close modal</span>
    </div>
    <v-dialog v-model="dialog" width="600" @change="close()">
      <v-carousel hide-delimiters v-model="slide" height="auto">
        <v-carousel-item
          v-for="(item, i) in photos"
          :src="item.src"
          :key="i"
        ></v-carousel-item>
      </v-carousel>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: 'PhotoCarousel',
  props: ['show', 'photos'],
  data() {
    return {
      slide: 0,
      dialog: false,
    }
  },
  methods: {
    close() {
      this.slide = 0
      this.$emit('close')
    },
  },
  watch: {
    show(val) {
      this.dialog = val
    },
    dialog(val) {
      val || this.close()
    },
  },
}
</script>

<style scoped>
.custom-close {
  color: #fff;
  z-index: 2000;
  cursor: pointer;
  position: fixed;
  width: calc(100% - 330px);
  bottom: 20px;
}
.custom-close span {
  background: #a7005775;
  text-align: right;
  padding: 3px 15px;
  border-radius: 15px;
  float: right;
}
</style>
