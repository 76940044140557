<template>
  <v-card flat tile class="d-flex pointer" @click="$emit('open')">
    <v-img :src="src" class="grey lighten-4" aspect-ratio="1" contain>
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular
            indeterminate
            color="grey lighten-4"
          ></v-progress-circular>
        </v-row>
      </template>
      <v-card-title
        class="text-center white--text text-uppercase"
        style="text-shadow: 1px 1px 3px #000;"
      >
      </v-card-title>
    </v-img>
  </v-card>
</template>

<script>
export default {
  name: 'PhotoCard',

  props: {
    id: null,
    src: null,
  },
}
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>
